<template>
    <div>
        <about-component/>
    </div>
</template>

<script>
    import AboutComponent from "@/components/about-us/AboutComponent";

    export default {
        name: "About",
        title: "Quiénes Somos | Private",
        components: {AboutComponent}
    }
</script>