<template>
    <v-container flui class="fill-height mt-5">
        <v-row dense no-gutters class="mb-5">
            <v-col cols="12" class="text-start justify-start">
                <p
                    class="
            font-weight-light
            display-2
            text-h4 text-md-h3
            grey--text
            text--darken-3
          "
                >
                    Nuestro Equipo de Trabajo
                </p>
                <v-row justify="center">
                    <div class="boxdivider"></div>
                </v-row>
            </v-col>
        </v-row>
        <v-row align="start" justify="center">
            <v-col
                v-for="(item, index) in workTeam"
                :key="index"
                cols="12"
                md="3"
                class="px-0 px-md-0"
            >
                <v-card class="border-info mx-1" rounded="0" elevation="0" outlined>
                    <div class="text-center">
                        <v-avatar height="200" width="200" max-width="100%" class="mt-3">
                            <v-img
                                :lazy-src="require('@/assets/images/no-image.jpg')"
                                :src="
                  item.image_url != null
                    ? item.image_url
                    : require('@/assets/images/no-image.jpg')
                "
                                class="rounded-t-lg"
                                height="100%"
                                max-width="100%"
                                position="top"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        align="center"
                                        class="fill-height ma-0"
                                        justify="center"
                                    >
                                        <v-progress-circular color="grey lighten-5" indeterminate>
                                        </v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-avatar>
                    </div>
                    <v-card-title style="white-space: nowrap;"
                                  class="
              mt-3
              text-caption text-left
              justify-left
              font-weight-bold
              text-wrap"
                    >
                        {{ item.name }}
                    </v-card-title>
                    <v-card-subtitle class="text-caption">
                        {{ item.job_position }}
                    </v-card-subtitle>
                    <v-card-text>
                        <!-- long paragraph -->
                        <div class="caption" :class="showFullText ? 'collapsible' : 'collapsed'">
                            {{ item.description }}
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn link text color="blue" small @click="showFullText = !showFullText">
                            Leer {{ readMoreText }}...
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center" class="ma-10" v-if="workTeam.length === 0">
            <div class="text-subtitle-1 text-md-h4">
                No existe personal en el Equipo de Trabajo...
            </div>
        </v-row>
    </v-container>
</template>

<script>
import workTeamService from "@/providers/WorkTeamService";

export default {
    name: "WorkTeamPage",
    data: () => ({
        showFullText: false,
        model: null,
        workTeam: [],
    }),
    computed: {
        readMoreText() {
            return this.showFullText ? 'menos' : 'más'
        }
    },
    methods: {
        getWorkTeam() {
            // Lista de Inversionistas
            workTeamService.getAllRecords().then((record) => {
                this.workTeam = record.value;
                console.log("workTeam: ", this.workTeam);
            });
        },
    },
    mounted() {
        this.showFullText = false;
        this.workTeam = [];
        this.getWorkTeam();
    },
};
</script>

<style scoped>
.v-btn {
    text-transform: none !important;
}

.collapsed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
